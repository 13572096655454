import BaseSelect from '@/components/common/base-select/base-select.vue'
import termOfValidity from '@/components/packages/term-of-validity/term-of-validity.vue'
import repaymentPlanItem from '../components/repayment-plan-item.vue'
import { getDictLists, filterMultiple } from '@/filters/fromDict'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import { formatDate } from '@/utils/auth/common'
import { validatorRequiredTerm } from '@/utils/validate'

// 融资方案
export const financingSchemeFrom = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  // 判断当获客方式为甲方推荐、同行推荐时，【推荐人】和【推荐人评价】为必填
  const falge = content.inFinancingData.financingPlanData.getCustomerType === 'FIRST_PARTY_RECOMMEND' || content.inFinancingData.financingPlanData.getCustomerType === 'SAME_INDUSTRY'
  console.log(content.inFinancingData.financingPlanData.finRepayPlans, '入口数据')
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: false, message: '请输入业务编号', trigger: 'change' }],
      attrs: {
        maxlength: 20,
        disabled: true,
        placeholder: '请输入'
      },
      cols
    },
    {
      label: '供应商名称',
      prop: 'gysCompanyName',
      rules: [{ required: false, message: '请输入供应商名称', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols: { lg: 10 }
    },
    {
      label: '融资产品',
      rules: [{ required: false, message: '请输入融资产品', trigger: 'change' }],
      prop: 'finaaceProductName',
      attrs: {
        maxlength: 50,
        disabled: true,
        placeholder: '请输入'
      },
      cols
    },
    // {
    //   label: '预计稳时付额度（元）',
    //   rules: [{ required: false, message: '请输入预计稳时付额度', trigger: 'blur' }],
    //   prop: 'expectedQuota',
    //   attrs: {
    //     placeholder: '请输入',
    //     type: 'Y'
    //   },
    //   tag: AmountInput,
    //   cols
    // },
    {
      label: '融资金额(元)',
      rules: [{ required: true, message: '请输入融资金额', trigger: 'blur' }],
      prop: 'financeAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Y'
      },
      tag: AmountInput,
      cols
    },
    {
      label: '融资期限',
      prop: 'financeTerm',
      rules: [{ required: true, validator: validatorRequiredTerm(content.inFinancingData.financingPlanData.financeTermUnit), trigger: 'change' }],
      attrs: {
        dictType: 'MM',
        placeholder: '请选择',
        maxlength: 3,
        type: 'ZS',
        validTimeUnit: content.inFinancingData.financingPlanData.financeTermUnit
      },
      on: {
        update (val) {
          content.changeValidTimeUnit(val)
        }
      },
      cols,
      tag: termOfValidity

    },
    {
      label: '综合融资利率(%)',
      rules: [{ required: true, message: '请输入融资利率', trigger: 'blur' }],
      prop: 'financeRate',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      cols,
      tag: AmountInput
    },
    {
      label: '质押率(%)',
      rules: [{ required: false, message: '请输入质押率', trigger: 'blur' }],
      prop: 'totalPledgeRate',
      attrs: {
        placeholder: '请输入',
        disabled: true,
        type: 'BL'
      },
      cols,
      tag: AmountInput
    },
    {
      label: '业务所属区域',
      prop: 'areaId',
      rules: [{ required: true, message: '请选择业务所属区域', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: getDictLists('BUSINESS_AREA'),
        selectedField: ['dictId', 'dictName']
      },
      cols,
      tag: BaseSelect
    },
    {
      label: '还款方式',
      prop: 'repayStatus',
      rules: [{ required: true, message: '请选择还款方式', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        formData: content.inFinancingData.financingPlanData.finRepayPlans
      },
      cols,
      tag: repaymentPlanItem,
      on: {
        changeRepayment () {
          content.changeRepayment()
        },
        submitdialog: (data) => {
          content.submitRepaymentPlanData(data)
        },
        closedialog: (data) => {
          content.closeRepaymentPlanData(data)
        }
      }
    },
    {
      label: '获客方式',
      rules: [{ required: false, message: '获客方式', trigger: 'change' }],
      prop: 'getCustomerType',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('CUSTOMER_ACQUISITION_MODE'),
        selectedField: ['dictId', 'dictName']
      },
      cols,
      tag: BaseSelect
    },
    {
      label: '推荐人',
      prop: 'recommender',
      rules: [falge ? { required: true, message: '请输入推荐人', trigger: 'blur' } : ''],
      attrs: {
        placeholder: '请输入',
        maxlength: 100
      },
      cols
    },
    {
      label: '担保模式',
      rules: [{ required: true, message: '请选择担保模式', trigger: 'change' }],
      prop: 'guaranteeType',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('GUARANTEE_TYPE'),
        selectedField: ['dictId', 'dictName']
      },
      cols,
      tag: BaseSelect
    },
    {
      label: '融资用途',
      prop: 'financeUse',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 4,
        maxlength: 2000
      },
      span: 24
    },
    {
      label: '还款来源',
      rules: [{ required: false, message: '业务编号', trigger: 'change' }],
      prop: 'repaySource',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 4,
        'show-word-limit': true,
        maxlength: '500'
      },
      cols: { xs: 24 }
    },
    {
      label: '推荐人评价',
      prop: 'recommenderEvaluation',
      rules: [falge ? { required: true, message: '请输入推荐人评价', trigger: 'blur' } : ''],
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 4,
        'show-word-limit': true,
        maxlength: '500'
      },
      cols: { xs: 24 }
    }
  ]
}
// 我司合作明细表格
export const cooperationTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '起息日',
      prop: 'startDate',
      formatter: row => {
        return formatDate(row.startDate, 'YY-MM-DD')
      }
    },
    {
      label: '到期日',
      prop: 'endDate',
      formatter: row => {
        return formatDate(row.endDate, 'YY-MM-DD')
      }
    },
    {
      label: '平台产品',
      prop: 'productName'
    },
    {
      label: '资金方',
      prop: 'capitalSideName'
    },
    {
      label: '融资金额（元）',
      prop: 'financeAmount',
      align: 'right',
      formatter: row => {
        return (row.financeAmount ? row.financeAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '融资利率（%）',
      prop: 'financeRate',
      formatter: row => {
        return (row.financeRate ? row.financeRate.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '成本价格（%）',
      prop: 'costPrice',
      formatter: row => {
        return (row.costPrice ? row.costPrice.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '服务费率（%）',
      prop: 'serviceRate',
      formatter: row => {
        return (row.serviceRate ? row.serviceRate.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '融资状态',
      prop: 'statusName'
    }
  ]
}
// 担保动态表格
export const GuaranteeStateTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '100px'
    },
    {
      label: '担保动态描述',
      prop: 'guaranteeDynamics',
      align: 'left',
      minWidth: '200px',
      'show-overflow-tooltip': true
    },
    {
      label: '操作',
      prop: 'action',
      width: '120px'
    }
  ]
}
// 尽调情况表格
export const investigationSituationTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '拜访日期',
      prop: 'visitDate',
      formatter: row => {
        return formatDate(row.preRepayDate, 'YY-MM-DD')
      },
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '拜访地址',
      prop: 'visitAddress',
      align: 'left',
      'show-overflow-tooltip': true,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '拜访企业',
      prop: 'visitCompany',
      align: 'left',
      'show-overflow-tooltip': true,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '拜访人员',
      prop: 'visitPersonnelList',
      align: 'left',
      'show-overflow-tooltip': true,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '现场合照',
      prop: 'finFileInfos',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '操作',
      prop: 'action',
      width: '120px'
    }

  ]
}
// 还款预览列表
export const viewList = (content) => {
  return [
    {
      label: '还款期数',
      prop: 'repayPeriods'
    },
    {
      label: '还款时间',
      prop: 'preRepayDate',
      formatter: row => {
        return formatDate(row.preRepayDate, 'YY-MM-DD')
      }
    },
    {
      label: '还款比例(%)',
      prop: 'preRepayRate',
      isHidden: content.FinRepayPlanDTO.repayType === '1'
    },
    {
      label: '还款本金(元)',
      prop: 'preRepayAmount',
      formatter: row => {
        return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0')
      }
    }
  ]
}
// 企业担保
export const enterpriseTable = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'companyName',
      align: 'left'
    },
    {
      label: '统一社会信用代码',
      prop: 'nsrsbh'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增企业担保查询表单
export const addEnterpriseForm = (content) => {
  return [
    {
      label: '企业名称',
      prop: 'enterpriseName',
      attrs: {
        placeholder: '请输入',
        maxlength: 50
      },
      on: {
        input: data => {
          content.queryParas.enterpriseName = data.replace(/\s+/g, '')
        }
      }
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      attrs: {
        placeholder: '请输入',
        maxlength: 18
      },
      on: {
        input: data => {
          content.queryParas.creditCode = data.replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 新增企业担保表格
export const addEnterpriseTable = (content) => {
  return [
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'enterpriseName'
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode'
    }
  ]
}
// 个人担保
export const personalTabel = (content) => {
  return [
    {
      prop: 'code',
      type: 'expand'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personType',
      formatter: row => {
        return filterMultiple('PERSONNEL_TYPE', row.personType, 'String')
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 个人担保子表格
export const personalFormChildren = (content) => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '家庭地址',
      prop: 'address',
      formatter: row => {
        return row.address ? row.address : '--'
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增个人担保查询
export const addPersonalFrom = (content) => {
  return [
    {
      label: '姓名',
      prop: 'personnerName',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.personnerName = data.replace(/[0-9]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '身份证',
      prop: 'idCard',
      attrs: {
        placeholder: '请输入',
        maxlength: 18
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.idCard = data.replace(/[\u4E00-\u9FA5]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 11
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.mobilePhoneNo = data.replace(/[\u4E00-\u9FA5]|[a-zA-Z]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 新增个人担保关系子表格
export const addRelationTableConfig = () => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '关系',
      prop: 'contactType'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 个人担保关系子表格
export const relationshipTableConfig = (content) => {
  return [

    {
      prop: 'implementSituation',

      width: '120px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType)
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 个人担保表格
export const tableConfig = (content) => {
  return [
    {
      type: 'selection',
      width: '55px'
    },

    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }
  ]
}
export const newColumns = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }
  ]
}
// 新增个人担保表格
export const addTableConfig = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '人员类别',
      prop: 'personTypeCode'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
